import * as React from "react"

const FoodIcon = () => {
  return (
    <svg
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        d="M17.761 10.491c-1.212.008-2.137-.126-2.898.849l-2.424 3.026-.635-.81 2.273-2.837c.752-.963.4-1.843.125-3.015-.268-1.136.342-1.604.79-2.069l4.607 3.599c-.346.565-.634 1.249-1.838 1.257zm-.569 12.406c-.124.097-.471.209-.702-.086L8.27 12.425l-1.839 1.437C3.435 8.728 1.006 2.97.932 1.351l16.347 20.844a.5.5 0 01-.087.702zM5.67 22.803a.503.503 0 01-.706.083.497.497 0 01-.083-.7l4.956-6.172.639.804-4.806 5.985zm17.631-19.56l-3.163 5.146-1.055-.827 3.692-4.73-.788-.616-3.694 4.729-.791-.618L21.199 1.6 20.41.985 16.712 5.71l-1.017-.795L19.858.556l-.715-.562S15.561 3.589 13.8 5.433c-.616.646-.776 1.629-.571 2.5.277 1.177.485 1.626.06 2.17L11.17 12.75A9341.024 9341.024 0 001.422.357.862.862 0 00.749.013C-.075.013.01.965.01.992c0 2.415 2.985 9.192 6.143 14.353l1.943-1.519 1.102 1.385-5.105 6.36a1.495 1.495 0 00.256 2.103 1.505 1.505 0 002.109-.256l4.656-5.798 4.589 5.806c.28.36.655.535 1.031.568.393.035.787-.084 1.074-.309a1.5 1.5 0 00.259-2.106c-.961-1.231-2.826-3.646-4.993-6.406l2.577-3.218c.432-.553.906-.457 2.117-.464.908-.006 1.855-.396 2.334-1.167C21.45 8.149 24 3.789 24 3.789l-.699-.546z"
        fill="#030405"
      />
    </svg>
  )
}

export default FoodIcon
