import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Stack, Typography, FormControl, MenuItem } from '@mui/material';
import { List, ListItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import toast from 'react-hot-toast';
import CalendarIcon from '../shared/CalendarIcon';
import ClockIcon from '../shared/ClockIcon';
import MapPinIcon from '../shared/MapPinIcon';
import ZapIcon from '../shared/ZapIcon';
import DietTextArea from './DietTextArea';
import RibbonIcon from '../shared/RibbonIcon';
import SendIcon from "../shared/SendIcon";
import ArrowLeft from '../shared/ArrowLeft';
import FoodIcon from "../shared/FoodIcon";
import ShoppingCartIcon from '../shared/ShoppingCartIcon';
import { fetchGuestList, updateGuest } from '../utils/services';
import type { Guest, GuestUpdate } from '../utils/types';

const RsvpView = () => {
  const [guest, setGuest] = useState<Guest>();
  const [guestList, setGuestList] = useState<Guest[]>([]);
  const [mode, setMode] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchGuestList()
    .then((guests) => setGuestList(guests.sort((a, b) => a.guest_name.localeCompare(b.guest_name))))
    .catch((err) => console.error(err));
  }, []);

  const onUpdateGuest = (newGuest: Guest) => {
    const request: GuestUpdate = {
      guest_name: newGuest.guest_name,
      guest_rsvp: newGuest.guest_rsvp,
      guest_diet: newGuest.guest_diet,
      has_plus_one: newGuest.has_plus_one,
      plus_one_name: newGuest.plus_one_name,
      plus_one_rsvp: newGuest.plus_one_rsvp,
      plus_one_diet: newGuest.plus_one_diet,
      address: newGuest.address,
      parking: newGuest.parking,
      credits: newGuest.credits,
    };
    updateGuest(request)
    .then(() => toast.success("RSVP saved!"))
    .catch((err) => {
      console.error(err); 
      toast.error("Something went wrong! Please try again later.");
    })
  }

  // Guest selector form
  const GuestSelector = () => {
    return (
      <FormControl fullWidth> 
        <Typography variant="h6" fontSize={14}>
          Full Name
        </Typography>
        <Select
          id="name-select"
          value={guest?.guest_name}
          fullWidth
          onChange={(e: SelectChangeEvent) => {
            const result = guestList.find(g => g.guest_name === (e.target.value as string))
            if (result) {
              setGuest({...result});
            }
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,  // Set your desired max height here
              },
            },
          }}
        >
          {guestList.map(g => (
            <MenuItem 
              key={`guest-name-${g.guest_name}`}
              value={g.guest_name}
              sx={{ fontFamily: 'DM Mono' }}
            >
              <Stack direction="row" spacing={2}>
                <Typography sx={{ fontFamily: 'DM Mono !important'}}>
                  {g.guest_name}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  const Mode0Content = () => {
    const isSmall = window.innerWidth < 768;
    return (
      <>
        <Typography variant={isSmall ? "h2" : "h1"} sx={{ color: 'black', mb: 4, fontFamily: "DM Mono !important" }}>
          Connie & Mike's{' '}
          <Typography 
            component="span" 
            variant={isSmall ? "h2" : "h1"} 
            sx={{ 
              color: '#355fa7', 
              fontFamily: "DM Mono !important"
            }}
          >
            Wedding
          </Typography>
        </Typography>
        <Typography sx={{ fontFamily: "DM Mono !important" }}>
          If you're responding for you and a guest, you'll be able to RSVP for your entire group.
        </Typography>
        <Box sx={{ width: "100%", my: 2 }}>
          <GuestSelector />
        </Box>
        <Button 
          variant="contained" 
          fullWidth 
          sx={{ borderRadius: 0, backgroundColor: "#355fa7" }}
          onClick={() => {
            if (!guest) {
              toast.error("Please select your name");
            } else {
              setMode(1);
            }
          }}
        >
          <Typography variant="h5" sx={{ fontFamily: "DM Mono !important", color: "white" }}>
            Find Your Invitation
          </Typography>
        </Button>
      </>
    );
  }
  
  const Mode1Content = () => {
    return (
      <>
        <Box sx={{ mb: 10 }} onClick={() => setMode(0)}>
          <ArrowLeft />
        </Box>
        <Box>
          <Stack direction="row" spacing={2} mb={2}>
            <Typography variant="h2" sx={{ textAlign: "left", color: 'black', mb: 4, fontFamily: "DM Mono !important" }}>
              RSVPs
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} mb={1} alignItems={"center"}>
            <CalendarIcon />
            <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
              Friday, June 13, 2025
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} mb={1} alignItems={"center"}>
            <MapPinIcon />
            <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
              800 E 4th Pl, Los Angeles, CA 90013
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} mb={1} alignItems={"center"}>
            <ClockIcon />
            <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
              5:30pm PST
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} mb={1} alignItems={"center"}>
            <ZapIcon />
            <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
              Dress code: Fun, Colorful, Zany
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ mt: 8 }}>
          <Grid container columns={12} sx={{ alignItems: "center", mb: 2 }}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
                {guest!.guest_name}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Button 
                fullWidth
                variant={guest?.guest_rsvp === true ? "contained" : "outlined"} 
                sx={{ color: "#355fa7", borderRadius: 0, px: 4, backgroundColor: guest?.guest_rsvp === true ? "#355fa7" : "transparent" }}
                onClick={() => setGuest({...guest!, guest_rsvp: true})}
              >
                <Typography variant="h5" sx={{ textAlign: "left", color: guest?.guest_rsvp === true ? 'white' : "#355fa7", fontFamily: "DM Mono !important" }}>
                  Accept
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Button 
                fullWidth
                variant={guest?.guest_rsvp === false ? "contained" : "outlined"} 
                sx={{ color: "#355fa7", borderRadius: 0, px: 4, backgroundColor: guest?.guest_rsvp === false ? "#355fa7" : "transparent" }}
                onClick={() => setGuest({...guest!, guest_rsvp: false})}
              >
                <Typography variant="h5" sx={{ textAlign: "left", color: guest?.guest_rsvp === false ? 'white' : "#355fa7", fontFamily: "DM Mono !important" }}>
                  Decline
                </Typography>
              </Button>
            </Grid>
          </Grid>
          {guest!.has_plus_one && guest!.guest_rsvp && guest!.plus_one_name && (
            <Grid container columns={12} sx={{ alignItems: "center" }}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
                  {guest!.plus_one_name}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Button 
                  fullWidth
                  variant={guest?.plus_one_rsvp === true ? "contained" : "outlined"} 
                  sx={{ color: "#355fa7", borderRadius: 0, px: 4, backgroundColor: guest?.plus_one_rsvp === true ? "#355fa7" : "transparent" }}
                  onClick={() => setGuest({...guest!, plus_one_rsvp: true})}
                >
                  <Typography variant="h5" sx={{ textAlign: "left", color: guest?.plus_one_rsvp === true ? 'white' : "#355fa7", fontFamily: "DM Mono !important" }}>
                    Accept
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Button 
                  fullWidth
                  variant={guest?.plus_one_rsvp === false ? "contained" : "outlined"} 
                  sx={{ color: "#355fa7", borderRadius: 0, px: 4, backgroundColor: guest?.plus_one_rsvp === false ? "#355fa7" : "transparent" }}
                  onClick={() => setGuest({...guest!, plus_one_rsvp: false})}
                >
                  <Typography variant="h5" sx={{ textAlign: "left", color: guest?.plus_one_rsvp === false ? 'white' : "#355fa7", fontFamily: "DM Mono !important" }}>
                    Decline
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
        <Box sx={{ mt: 4 }}>
          <Button 
            variant="contained" 
            fullWidth 
            sx={{ borderRadius: 0, backgroundColor: "#355fa7" }}
            onClick={() => {
              if (guest?.guest_rsvp === undefined || (guest?.guest_rsvp === true && guest.has_plus_one && guest.plus_one_rsvp === undefined)) {
                toast.error("Please RSVP for all parties");
              } else if (guest?.guest_rsvp === false) {
                setMode(4);
                onUpdateGuest(guest);
              } else {
                setMode(2);
              }
            }}
          >
            <Typography variant="h5" sx={{ fontFamily: "DM Mono !important", color: "white" }}>
              {guest?.guest_rsvp === false ? "Wrap up" : "Continue"}
            </Typography>
          </Button>
        </Box>
      </>
    );
  }

  const Mode2Content = () => {
    return (
      <>
        <Box sx={{ mb: 10 }} onClick={() => setMode(1)}>
          <ArrowLeft />
        </Box>
        <Box>
          <Typography variant="h2" sx={{ textAlign: "left", color: 'black', mb: 2, fontFamily: "DM Mono !important" }}>
            Dinner
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "left", color: 'black', mb: 2, fontFamily: "DM Mono !important" }}>
            Do you have any dietary restrictions? 
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "left", color: 'black', mb: 2, fontFamily: "DM Mono !important" }}>
            e.g. allergic to shellfish, vegetarian, ...
          </Typography>
        </Box>
        <Box sx={{ mt: 8 }}>
          <Grid container columns={12} sx={{ alignItems: "center", mb: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
                {guest!.guest_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <DietTextArea text={guest!.guest_diet || ""} setText={(text: string) => setGuest({...guest!, guest_diet: text})} numRows={4}/>
            </Grid>
          </Grid>
          {guest?.has_plus_one && guest?.guest_rsvp && (
            <Grid container columns={12} sx={{ alignItems: "center", mb: 2 }}>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
                  {guest!.plus_one_name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={8}>
                <DietTextArea text={guest!.plus_one_diet || ""} setText={(text: string) => setGuest({...guest!, plus_one_diet: text})} numRows={4} />
              </Grid>
            </Grid>
          )}
        </Box>
        <Box sx={{ mt: 4 }}>
          <Button 
            variant="contained" 
            fullWidth 
            sx={{ borderRadius: 0, backgroundColor: "#355fa7" }}
            onClick={() => setMode(3)}
          >
            <Typography variant="h5" sx={{ fontFamily: "DM Mono !important", color: "white" }}>
              Continue
            </Typography>
          </Button>
        </Box>
      </>
    );
  }

  const Mode3Content = () => {
    return (
      <>
        <Box sx={{ mb: 10 }} onClick={() => setMode(2)}>
          <ArrowLeft />
        </Box>
        <Box>
          <Typography variant="h2" sx={{ textAlign: "left", color: 'black', mb: 2, fontFamily: "DM Mono !important" }}>
            Last few things
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "left", color: 'black', mb: 2, fontFamily: "DM Mono !important" }}>
            Cross the t's and dot the i's
          </Typography>
        </Box>
        <Box sx={{ mt: 8 }}>
          <Grid container columns={12} sx={{ alignItems: "center", mb: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
                Mailing Address
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <DietTextArea text={guest!.address || ""} setText={(text: string) => setGuest({...guest!, address: text})} numRows={2} />
            </Grid>
          </Grid>
          <Grid container columns={12} sx={{ alignItems: "center", mb: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
                Parking
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Button 
                fullWidth
                variant={guest?.parking === true ? "contained" : "outlined"} 
                sx={{ color: "#355fa7", borderRadius: 0, px: 4, backgroundColor: guest?.parking === true ? "#355fa7" : "transparent" }}
                onClick={() => setGuest({...guest!, parking: true})}
              >
                <Typography variant="h5" sx={{ textAlign: "left", color: guest?.parking === true ? 'white' : "#355fa7", fontFamily: "DM Mono !important" }}>
                  Required
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Button 
                fullWidth
                variant={guest?.parking === false ? "contained" : "outlined"} 
                sx={{ color: "#355fa7", borderRadius: 0, px: 4, backgroundColor: guest?.parking === false ? "#355fa7" : "transparent" }}
                onClick={() => setGuest({...guest!, parking: false})}
              >
                <Typography variant="h5" sx={{ textAlign: "left", color: guest?.parking === false ? 'white' : "#355fa7", fontFamily: "DM Mono !important" }}>
                  No need
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Button 
            variant="contained" 
            fullWidth 
            sx={{ borderRadius: 0, backgroundColor: "#355fa7" }}
            onClick={() => {
              if (guest?.parking === undefined) {
                toast.error("Parking information missing");
              } else if (guest?.address === undefined || guest?.address.length === 0) {
                toast.error("Mailing address missing");
              } else {
                setMode(4);
                onUpdateGuest(guest);
              }
            }}
          >
            <Typography variant="h5" sx={{ fontFamily: "DM Mono !important", color: "white" }}>
              Wrap Up
            </Typography>
          </Button>
        </Box>
      </>
    );
  }

  const Mode4Content = () => {
    return (
      <>
        <Box>
          <Typography variant="h2" sx={{ textAlign: "left", color: 'black', mb: 2, fontFamily: "DM Mono !important" }}>
            {guest?.guest_rsvp ? "一路顺风" : "下次见"}
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "left", color: 'black', mb: 2, fontFamily: "DM Mono !important" }}>
            Here's a recap of your RSVP
          </Typography>
        </Box>
        <Box sx={{ mt: 8 }}>
          <Grid container columns={12} gap={1} sx={{ alignItems: "flex-start", mb: 2 }}>
            <Grid item lg={3}>
              <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important", mt: 2 }}>
                {guest?.guest_name}
              </Typography>
            </Grid>
            <Grid item lg={8}>
              <List>
                <ListItem sx={{ gap: 2 }}>
                  <RibbonIcon />
                  <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
                    {guest?.guest_rsvp ? "Attending" : "Unable to attend"}
                  </Typography>
                </ListItem>
                {guest?.guest_rsvp && (
                  <ListItem sx={{ gap: 2, alignItems: "flex-start" }}>
                    <SendIcon />
                    <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
                      {guest?.address}
                    </Typography>
                  </ListItem>
                )}
                {guest?.guest_rsvp && guest?.parking && (
                  <ListItem sx={{ gap: 2 }}>
                    <ShoppingCartIcon />
                    <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
                      Reserved parking spot
                    </Typography>
                  </ListItem>
                )}
                {guest?.guest_rsvp && guest?.guest_diet && (
                  <ListItem sx={{ gap: 2 }}>
                    <FoodIcon />
                    <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
                      {guest?.guest_diet}
                    </Typography>
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
          {guest?.has_plus_one && guest?.guest_rsvp &&  (
            <Grid container columns={12} gap={1} sx={{ alignItems: "flex-start", mb: 2 }}>
              <Grid item lg={3}>
                <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important", mt: 2 }}>
                  {guest?.plus_one_name}
                </Typography>
              </Grid>
              <Grid item lg={8}>
              <List>
                <ListItem sx={{ gap: 2 }}>
                  <RibbonIcon />
                  <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
                    {guest?.plus_one_rsvp ? "Attending" : "Unable to attend"}
                  </Typography>
                </ListItem>
                {guest?.plus_one_rsvp && guest?.plus_one_diet && (
                  <ListItem sx={{ gap: 2 }}>
                    <FoodIcon />
                    <Typography variant="h5" sx={{ textAlign: "left", color: 'black', fontFamily: "DM Mono !important" }}>
                      {guest?.plus_one_diet}
                    </Typography>
                  </ListItem>
                )}
              </List>
              </Grid>
            </Grid>
          )}
        </Box>
        <Box sx={{ mt: 4 }}>
          <Button 
            variant="contained" 
            fullWidth 
            sx={{ borderRadius: 0, backgroundColor: "#355fa7" }}
            onClick={() => navigate('/regi3')}
          >
            <Typography variant="h5" sx={{ fontFamily: "DM Mono !important", color: "white" }}>
              Go to registry
            </Typography>
          </Button>
        </Box>
      </>
    );
  }
  
  return (
    <Grid
      container 
      columns={12}
      sx={{ 
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: "row",
      }}
    >
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{
          backgroundImage: `url(https://lucymuli.shop/cdn/shop/files/LucyLi_HeartOpen_8_final.jpg?v=1735100063&width=1206)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '400px'
        }}
      >
        <Typography sx={{ color: "black", fontFamily: "DM Mono !important", py: 2, px: 4 }}>
          Credits to Lucy Mu Li "Heart Open to the Sky" artwork.
        </Typography>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 4,
          backgroundColor: '#ddd2bf',
          px: 8,
        }}
      >
        {mode === 0 && <Mode0Content />}
        {mode === 1 && <Mode1Content />}
        {mode === 2 && <Mode2Content />}
        {mode === 3 && <Mode3Content />}
        {mode === 4 && <Mode4Content />}
      </Grid>
    </Grid>
  );
}

export default RsvpView;