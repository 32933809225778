import { Box, Card, CardContent, Typography } from '@mui/material';
import type { CardType } from './utils';

type InputProps = {
  card: CardType;
}

const MenuCard = ({ card }: InputProps) => {
  return (
    <Card 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        bgcolor: card.bgColor,
        boxShadow: 'none',
        borderRadius: 8,
        border: `20px solid ${card.borderColor}`,
      }}
    >
      <CardContent sx={{ 
        p: 3,
        pt: 1, 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
      }}>
        <img 
          src={card.image}
          alt="Description of the image"
          style={{ width: '100%', maxWidth: '500px', borderRadius: '8px' }} 
        />
        <Typography 
          variant="h5" 
          component="h2" 
          sx={{ 
            color: "#fdf3dd",
            fontFamily: "Shrikhand !important",
            fontSize: { 
              xs: 50,    // Small screens (phones)
              sm: 40,    // Medium screens (tablets)
              md: 50,    // Large screens (desktops)
              lg: 60     // Extra large screens
            },
            textAlign: "center",
          }}
        >
          {card.title}
        </Typography>
        {card.subtitle && (
          <Typography 
            variant="h5" 
            component="h2" 
            sx={{ 
              color: "#fdf3dd",
              opacity: 0.7,
              fontFamily: "Shrikhand !important",
              fontSize: { 
                xs: 20,    // Small screens (phones)
                sm: 16,    // Medium screens (tablets)
                md: 24,    // Large screens (desktops)
                lg: 30     // Extra large screens
              },
              textAlign: "center",
            }}
          >
            {card.subtitle}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default MenuCard;