import React, { useState } from 'react';
import { TextField } from '@mui/material';

type InputProps = {
  text: string;
  setText: (text: string) => void;
  numRows: number;
}

const DietTextArea = ({ text, setText, numRows}: InputProps) => {
  const [localText, setLocalText] = useState<string>(text);
  return (
    <TextField
      multiline
      rows={numRows}
      fullWidth
      variant="outlined"
      defaultValue={text}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setLocalText(event.target.value as string);
      }}
      onBlur={() => setText(localText)}
      sx={{ 
        backgroundColor: 'transparent',
        '& .MuiOutlinedInput-root': {
          fontFamily: 'DM Mono',
          '& fieldset': {
            borderColor: "#355fa7",
          },
          '&:hover fieldset': {
            borderColor: "#355fa7",
          },
        }
      }}
    />
  );
}

export default DietTextArea;