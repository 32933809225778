import React from 'react';
import './App.css'
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import MenuView from './menu/MenuView';
import MainView from './components/MainView';
import RsvpView from './rsvp/RsvpView';
import RegistryView from './registry/RegistryView';
import GalleryView from './registry/GalleryView';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className="App">
      <Box>
        <CssBaseline />
        <Toaster
          position="top-center"
          toastOptions={{
            duration: 5000,
            style: { background: "black", color: "#EEEDEB" },
          }}
        />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MenuView />} />
            <Route path="/invite" element={<MainView />} />
            <Route path="/rsvp" element={<RsvpView />} />
            <Route path="/regi3" element={<RegistryView />} />
            <Route path="/regi3/gallery" element={<GalleryView />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </div>
  );
}

export default App;
