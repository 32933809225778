import React, { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import Sparkle from 'react-sparkle'
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Modal, Stack, Typography, FormControl, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import type { Guest, Nft, NftCreate, CreditUpdate } from '../utils/types';
import GatchaCard from './GatchaCard';
import { mintNft, fetchGuestList, fetchNftGallery, updateGuestCredits } from '../utils/services';
import Cookies from 'js-cookie';
import ReactDice, { ReactDiceRef } from 'react-dice-complete'

const COOKIE_KEY: string = 'wuliucom-regi3-user-id';

const RegistryView = () => {
  const [isHovered1, setIsHovered1] = useState<boolean>(false);
  const [isHovered2, setIsHovered2] = useState<boolean>(false);
  const [isHovered3, setIsHovered3] = useState<boolean>(false);
  const [mintedNft, setMintedNft] = useState<Nft>();
  const [guestList, setGuestList] = useState<Guest[]>([]);
  const [galleryNfts, setGalleryNfts] = useState<Nft[]>([]);
  const [chosenGuest, setChosenGuest] = useState<Guest>();
  const [showDeck, setShowDeck] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModal2, setShowModal2] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<number>(0);
  const [formMode2, setFormMode2] = useState<number>(0);
  const [loadingNft, setLoadingNft] = useState<boolean>(false);
  const [totalRoll, setTotalRoll] = useState<number>(0);
  const reactDice = useRef<ReactDiceRef>(null);

  const navigate = useNavigate();
  const isSmall = window.innerWidth < 768;

  useEffect(() => {
    // Attempt to fetch a stored userId
    const guestId: string | undefined = Cookies.get(COOKIE_KEY);
    // Fetch guest list
    fetchGuestList()
    .then((guests) => {
      const sortedGuests = guests.sort((a, b) => a.guest_name.localeCompare(b.guest_name));
      setGuestList(sortedGuests);
      if (guestId) {
        const currGuest = sortedGuests.find(guest => guest.guest_id === guestId);
        if (currGuest) setChosenGuest(currGuest);
      }
    })
    .catch((err) => console.error(err));
    // Fetch nfts
    fetchNftGallery()
    .then((nfts) => setGalleryNfts(nfts.sort((a, b) => a.created_at - b.created_at)))
    .catch((err) => console.error(err));
    return () => { setTotalRoll(0); }
  }, [])

  const onMintNft = async (guestId: string, ownerName: string, deck: number): Promise<void> => {
    const body: NftCreate = {
      guest_id: guestId,
      owner_name: ownerName,
      deck: deck,
    };
    setLoadingNft(true);
    mintNft(body)
    .then((nft) => {
      setMintedNft(nft);
      toast.success("NFT unlocked!");
      if (chosenGuest) {
        // Update the number of credits the user has
        const oldCredits = chosenGuest.credits || 20;
        const price = showDeck === 1 ? 5 : showDeck === 2 ? 10 : 20;
        const newCredits = Math.max(oldCredits - price, 0);
        // Updates the frontend...
        setChosenGuest({...chosenGuest, credits: newCredits});
        // Update the backend
        const body2: CreditUpdate = { guest_id: chosenGuest.guest_id!, credits: newCredits };
        updateGuestCredits(body2);
      }
    })
    .catch((err) => {
      console.error(err); 
      toast.error("Something went wrong! Please contact us!");
    })
    .finally(() => {
      setLoadingNft(false);
    });
  }

  const TitleScreen = () => {
    return (
      <Box
        style={{
          backgroundImage: `url(/regi3/cloud-background.png)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: "100%",
          minHeight: 800,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        sx={{ px: 6, }}
      >
        <Typography 
          sx={{ 
            textAlign: "center", 
            color: "gray", 
            fontWeight: "bold",
            fontFamily: "Comic Neue !important", 
            fontSize: isSmall ? 40 : 50,
          }}
        >
          {chosenGuest ? `welcome back, ${chosenGuest.guest_name.toLowerCase().split(' ')[0]}!` : "welcome to"}
        </Typography>
        <img 
          src="/regi3/regi3.png" 
          alt="REGI3 title"
          style={{ maxWidth: '100%' }}  
        />
        <Typography 
          sx={{ 
            textAlign: "center", 
            color: "gray", 
            fontWeight: "bold",
            fontFamily: "Comic Neue !important", 
            fontSize: 24,
            pt: 1,
          }}
        >
          lost? Back to <a href="/rsvp">rsvp</a>
        </Typography>
        {chosenGuest && (
          <Box sx={{ my: 8 }}>
            <Button 
              sx={{ 
                backgroundColor: "#FF66C4", 
                borderRadius: 6, 
                '&:hover': {
                  opacity: 1,
                  backgroundColor: 'black'
                }
              }}
              disabled={true}
            >
              <Sparkle />
              <Typography
                sx={{ 
                  textAlign: "center", 
                  color: "white", 
                  fontWeight: "bold",
                  fontFamily: "Comic Neue !important", 
                  fontSize: 24,
                  p: 2,
                }}
              >
                {`you've got ${chosenGuest.credits} credits left`}
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
    )
  }

  const InfoScreen = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          py: 8,
          px: isSmall ? 4 : 16,
        }}
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url(/regi3/spiral.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: "100%",
        }}
      >
        <Box>
          <Typography 
            sx={{ 
              textAlign: "center", 
              color: "black", 
              fontWeight: "bold",
              fontFamily: "Comic Neue !important", 
              fontSize: isSmall ? 40 : 50,
            }}
          >
            WHAT IS REGI3?
          </Typography>
          <Box sx={{ py: 4, px: isSmall ? 4 : 16, }}>
            <Typography 
              sx={{ 
                textAlign: "center", 
                color: "black", 
                fontWeight: "bold",
                fontFamily: "Comic Neue !important", 
                fontSize: isSmall ? 30 : 42,
              }}
            >
              We are so glad you asked. It’s part web3 project, part art project, part cat tribute. 
            </Typography>
            <Box sx={{ py: 4 }}>
              <Typography 
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontFamily: "Comic Neue !important", 
                  fontSize: 24,
                  fontWeight: "bold",
                }}
              >
                We quickly realized a classic gift registry didn't make sense for us — after living together for the last 8 years, there's nothing new we really need for the house! However, if you can't help but get us a gift, we wanted to at least make it fun for you...
              </Typography>
              <Typography 
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontFamily: "Comic Neue !important", 
                  fontSize: 24,
                  fontWeight: "bold",
                  pt: 4,
                }}
              >
                Welcome to regi3 — a cat art gacha. 🔮 For those unfamiliar with the world of gacha, it's sort of like a gumball machine. But instead of getting random gumball flavors … you’re getting random AI-generated cat trading cards!
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ pt: 4 }}>
          <Typography 
            sx={{ 
              textAlign: "center", 
              color: "black", 
              fontWeight: "bold",
              fontFamily: "Comic Neue !important", 
              fontSize: isSmall ? 40 : 50,
            }}
          >
            HOW IS THIS ART?
          </Typography>
          <Box sx={{ py: 4, px: isSmall ? 4 : 16 }}>
            <Typography 
              sx={{ 
                textAlign: "center", 
                color: "black", 
                fontFamily: "Comic Neue !important", 
                fontWeight: "bold",
                fontSize: 24,
                py: 2,
              }}
            >
              Well, it’s generated with an obnoxious blend of AI, stolen NFT inspiration, borrowed Neopets assets, and the unapproved likenesses of our cats — so if you hate it, blame the technology. The nice part about digital art though is you can do whatever you want with it! Make it your profile picture! Print it out and wrap a fish in it! Put it in the digital trash can!
            </Typography>
          </Box>
        </Box>
        <Box sx={{ pt: 4 }}>
          <Typography 
            sx={{ 
              textAlign: "center", 
              color: "black", 
              fontWeight: "bold",
              fontFamily: "Comic Neue !important", 
              fontSize: isSmall ? 40 : 50,
            }}
          >
            WHERE CAN I SEE MY NFT?
          </Typography>
          <Box 
            sx={{ 
              py: 4,  
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography 
              sx={{ 
                textAlign: "center", 
                color: "black", 
                fontFamily: "Comic Neue !important", 
                fontSize: 24,
                fontWeight: "bold",
                py: 2,
                pb: 6,
              }}
            >
              Why, so kind of you to ask! Find your NFT and everyone else's in the gallery. 
            </Typography>
            <Button 
              sx={{ 
                backgroundColor: "#FF66C4", 
                borderRadius: 6, 
                '&:hover': {
                  opacity: 1,
                  backgroundColor: 'black'
                }
              }}
              onClick={() => navigate("/regi3/gallery")}
            >
              <Sparkle />
              <Typography
                sx={{ 
                  textAlign: "center", 
                  color: "white", 
                  fontWeight: "bold",
                  fontFamily: "Comic Neue !important", 
                  fontSize: 24,
                  p: 2,
                }}
              >
                see full gallery
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  const InstructionScreen = () => {
    return (
      <Box
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url(/regi3/machines.png)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: "100%",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        sx={{
          py: 8,
          px: isSmall ? 4 : 16,
        }}
      >
        <Typography 
          sx={{ 
            textAlign: "center", 
            color: "black", 
            fontWeight: "bold",
            fontFamily: "Comic Neue !important", 
            fontSize: isSmall ? 40 : 50,
          }}
        >
          OK WHATEVER, HOW DO I GET ONE?
        </Typography>
        <Box 
          sx={{ 
            py: 4,  
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            px: isSmall ? 4 : 16,
          }}
        >
          <Typography 
            sx={{ 
              textAlign: "center", 
              color: "black", 
              fontWeight: "bold",
              fontFamily: "Comic Neue !important", 
              fontSize: 24,
              py: 2,
            }}
          >
            Wow! Really? You mean it? Well go right on ahead! Click the “gacha” button on any of the machines below. There are 3 tiers of cat cards that cost different amounts of “credits”.
          </Typography>
          <Typography 
            sx={{ 
              textAlign: "center", 
              color: "black", 
              fontWeight: "bold",
              fontFamily: "Comic Neue !important", 
              fontSize: 36,
              py: 2,
            }}
          >
            We’ve pre-loaded 20 credits to each of your accounts
          </Typography>
          <Typography 
            sx={{ 
              textAlign: "center", 
              color: "black",
              fontWeight: "bold", 
              fontFamily: "Comic Neue !important", 
              fontSize: 24,
              py: 2,
            }}
          >
            Use that to generate your first card, and then the best gift you can give us is playing more of our goofy game! Nothing would make us happier than hundreds and hundreds of generated cat cards!!! If you run out, get more credits by clicking “GET MOAR CREDITS”. There will be an option to send us a gift, but even if you skip it, the Great Cat Oracle will shower you with additional credits!!
          </Typography>
          <Typography 
            sx={{ 
              textAlign: "center", 
              color: "black", 
              fontWeight: "bold",
              fontFamily: "Comic Neue !important", 
              fontSize: 36,
              py: 2,
            }}
          >
            All your cat cards will be featured in our gallery. See if you can score the elusive 4 diamond cat!! 💎💎💎💎
          </Typography>
        </Box>
        <Box sx={{ mb: 10 }}>
          <Button 
            sx={{ backgroundColor: "#FF66C4", borderRadius: 6 }}
            onClick={() => {
              setShowModal(false);
              setShowModal2(true);
            }}
          >
            <Sparkle />
            <Typography
              sx={{ 
                textAlign: "center", 
                color: "white", 
                fontWeight: "bold",
                fontFamily: "Comic Neue !important", 
                fontSize: 24,
                p: 2,
              }}
            >
              GET MOAR CREDITS
            </Typography>
          </Button>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Grid container columns={12} sx={{ width: "100%" }}>
            <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center", mb: 8 }} xs={12} sm={6} lg={4}>
              {/* First cat (woocat) */}
              <div style={{ 
                position: 'relative', 
                width: 200, 
                height: 300, 
                marginLeft: 32, 
                marginBottom: 20 
              }}>
                {/* Base image (always loaded) */}
                <img 
                  src="/regi3/woocat1.png"
                  alt="woocat base state"
                  width={200}
                  style={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: isHovered1 ? 0 : 1,
                    transition: 'opacity 0.3s ease'
                  }}
                />
                {/* Hover image (also always loaded) */}
                <img 
                  src="/regi3/woocat2.png"
                  alt="woocat hover state"
                  width={200}
                  style={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: isHovered1 ? 1 : 0,
                    transition: 'opacity 0.3s ease'
                  }}
                />
                {/* Invisible overlay to handle mouse events */}
                <div 
                  style={{ 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    height: '100%',
                    cursor: 'pointer'
                  }}
                  onMouseEnter={() => setIsHovered1(true)}
                  onMouseLeave={() => setIsHovered1(false)}
                />
              </div>
              <Typography 
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontWeight: "bold",
                  fontFamily: "Comic Neue !important", 
                  fontSize: 30,
                }}
              >
                woocat
              </Typography>
              <Typography 
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontFamily: "Comic Neue !important", 
                  fontSize: 24,
                }}
              >
                base cat in a hat
              </Typography>
              <Typography 
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontFamily: "Comic Neue !important", 
                  fontSize: 24,
                  fontStyle: 'italic' 
                }}
              >
                pretty cool
              </Typography>
              <Typography 
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontWeight: "bold",
                  fontFamily: "Comic Neue !important", 
                  fontSize: isSmall ? 40 : 50,
                }}
              >
                5 credits
              </Typography>
              <Button 
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  backgroundColor: "black", 
                  borderRadius: 6, 
                  my: 2, 
                  '&:hover': {
                    opacity: 1,
                    backgroundColor: 'black'
                  }
                }}
                onClick={() => {
                  setShowDeck(1);
                  setShowModal(true);
                }}
              >
                <Typography
                  sx={{ 
                    textAlign: "center", 
                    color: "white", 
                    fontWeight: "bold",
                    fontFamily: "Comic Neue !important", 
                    fontSize: 24,
                    p: 2,
                  }}
                >
                  gacha?
                </Typography>
              </Button>
            </Grid>
            <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center", mb: 8  }} xs={12} sm={6} lg={4}>
            <div style={{ 
              position: 'relative', 
              width: 200, 
              height: 300, 
              marginLeft: 32, 
              marginBottom: 20 
            }}>
              <img 
                src="/regi3/loocat1.png"
                alt="loocat base state"
                width={200}
                style={{ 
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  opacity: isHovered2 ? 0 : 1,
                  transition: 'opacity 0.3s ease'
                }}
              />
              <img 
                src="/regi3/loocat2.png"
                alt="loocat hover state"
                width={200}
                style={{ 
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  opacity: isHovered2 ? 1 : 0,
                  transition: 'opacity 0.3s ease'
                }}
              />
              <div 
                style={{ 
                  position: 'absolute', 
                  top: 0, 
                  left: 0, 
                  width: '100%', 
                  height: '100%',
                  cursor: 'pointer'
                }}
                onMouseEnter={() => setIsHovered2(true)}
                onMouseLeave={() => setIsHovered2(false)}
              />
            </div>
              <Typography 
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontWeight: "bold",
                  fontFamily: "Comic Neue !important", 
                  fontSize: 30,
                }}
              >
                loocat
              </Typography>
              <Typography 
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontFamily: "Comic Neue !important", 
                  fontSize: 24,
                }}
              >
                cat in a hat + shirt!!
              </Typography>
              <Typography 
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontFamily: "Comic Neue !important", 
                  fontSize: 24,
                  fontStyle: 'italic' 
                }}
              >
                super cool
              </Typography>
              <Typography 
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontWeight: "bold",
                  fontFamily: "Comic Neue !important", 
                  fontSize: isSmall ? 40 : 50,
                }}
              >
                10 credits
              </Typography>
              <Button 
                sx={{ 
                  backgroundColor: "black", 
                  borderRadius: 6, 
                  my: 2, 
                  '&:hover': {
                    opacity: 1,
                    backgroundColor: 'black'
                  }
                }}
                onClick={() => {
                  setShowDeck(2);
                  setShowModal(true);
                }}
              >
                <Typography
                  sx={{ 
                    textAlign: "center", 
                    color: "white", 
                    fontWeight: "bold",
                    fontFamily: "Comic Neue !important", 
                    fontSize: 24,
                    p: 2,
                  }}
                >
                  gacha!
                </Typography>
              </Button>
            </Grid>
            <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center", mb: 8  }} xs={12} sm={6} lg={4}>
              {/* Third cat (wooloocat) */}
              <div style={{ 
                position: 'relative', 
                width: 165, 
                height: 300, 
                marginLeft: 0, 
                marginBottom: 20 
              }}>
                <img 
                  src="/regi3/wooloocat1.png"
                  alt="wooloocat base state"
                  width={165}
                  style={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: isHovered3 ? 0 : 1,
                    transition: 'opacity 0.3s ease'
                  }}
                />
                <img 
                  src="/regi3/wooloocat2.png"
                  alt="wooloocat hover state"
                  width={165}
                  style={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: isHovered3 ? 1 : 0,
                    transition: 'opacity 0.3s ease'
                  }}
                />
                <div 
                  style={{ 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    height: '100%',
                    cursor: 'pointer'
                  }}
                  onMouseEnter={() => setIsHovered3(true)}
                  onMouseLeave={() => setIsHovered3(false)}
                />
              </div>
              <Typography 
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontWeight: "bold",
                  fontFamily: "Comic Neue !important", 
                  fontSize: 30,
                }}
              >
                wooloocat
              </Typography>
              <Typography
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontFamily: "Comic Neue !important", 
                  fontSize: 24,
                }}
              >
                cat in a hat + shirt + accessory!!
              </Typography>
              <Typography 
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontFamily: "Comic Neue !important", 
                  fontSize: 24,
                  fontStyle: 'italic' 
                }}
              >
                ✨ <span style={{ textDecoration: 'underline' }}>holographic</span> ✨
              </Typography>
              <Typography 
                sx={{ 
                  textAlign: "center", 
                  color: "black", 
                  fontWeight: "bold",
                  fontFamily: "Comic Neue !important", 
                  fontSize: isSmall ? 40 : 50,
                }}
              >
                20 credits
              </Typography>
              <Button 
                sx={{ 
                  backgroundColor: "black", 
                  borderRadius: 6, 
                  my: 2, 
                  '&:hover': {
                    opacity: 1,
                    backgroundColor: 'black'
                  }
                }}
                onClick={() => {
                  setShowDeck(3);
                  setShowModal(true);
                }}
              >
                <Sparkle />
                <Typography
                  sx={{ 
                    textAlign: "center", 
                    color: "white", 
                    fontWeight: "bold",
                    fontFamily: "Comic Neue !important", 
                    fontSize: 24,
                    p: 2,
                  }}
                >
                  gachaaah
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }

  const CarouselScreen = () => {
    return (
      <Box sx={{ 
        py: 4,
        backgroundImage: `url(/regi3/starrysky-bg.png)`,
        backgroundSize: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat',
        overflow: "hidden",
      }}>
        <Box
          sx={{
            display: "flex",
            width: "max-content", // Important for horizontal scrolling
            animation: "scroll 60s linear infinite",
            "@keyframes scroll": {
              "0%": { transform: "translateX(0)" },
              "100%": { transform: `translateX(calc(-320px * ${galleryNfts.length}))` }
            }
          }}
        >
          {galleryNfts.map(nft => (
            <Box 
              key={`nft-carousel-${nft.nft_id}`} 
              sx={{ 
                mx: 2,
                display: "inline-block"
              }}
            >
              <GatchaCard nft={nft} />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  const FooterScreen = () => {
    return (
      <Box sx={{ p: 8, backgroundColor: "black" }}>
        <Typography sx={{ textAlign: "center", color: "white", fontFamily: "Comic Neue !important" }}>
          Designed by Connie L. Built by Mike W. Art inspired by the Cool Cats NFT collection. 
        </Typography>
      </Box>
    )
  }

  // Guest selector form
  const GuestSelector = () => {
    return (
      <FormControl fullWidth> 
        <Select
          id="name-select"
          value={chosenGuest?.guest_name}
          fullWidth
          onChange={(e: SelectChangeEvent) => {
            const result = guestList.find(g => g.guest_name === (e.target.value as string))
            if (result) {
              setChosenGuest({...result});
              if (result.guest_id) {
                Cookies.set(COOKIE_KEY, result.guest_id, { expires: 365 });
              }
            }
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,  // Set your desired max height here
              },
            },
          }}
        >
          {guestList.map(g => (
            <MenuItem 
              key={`guest-name-${g.guest_name}`}
              value={g.guest_name}
              sx={{ fontFamily: 'DM Mono' }}
            >
              <Stack direction="row" spacing={2}>
                <Typography sx={{ fontFamily: 'DM Mono !important'}}>
                  {g.guest_name}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  const MoreCreditsModalContent0 = () => {
    return (
      <>
        <Typography
          sx={{ 
            textAlign: "left", 
            fontWeight: "bold",
            fontFamily: "Comic Neue !important", 
            fontSize: 32,
          }}
        >
          {`what... u want MOAR CREDS?`}
        </Typography>
        <Box sx={{ width: "100%", my: 2 }}>
          <Typography
            sx={{ 
              textAlign: "left", 
              fontWeight: "bold",
              fontFamily: "Comic Neue !important", 
              fontSize: 18,
            }}
          >
            Who are you?
          </Typography>
          <GuestSelector />
        </Box>
        <Box>
          <Button 
            sx={{ 
              backgroundColor: "#FF66C4", 
              borderRadius: 4, 
              '&:hover': {
                opacity: 1,
                backgroundColor: 'black'
              },
              mt: 1,
              mb: 2,
            }}
            fullWidth
            href={`https://account.venmo.com/payment-link?txn=pay&recipients=ckliu`}
            target="_blank" 
            rel="noopener noreferrer"
          >
            <Sparkle />
            <Typography
              sx={{ 
                textAlign: "center", 
                color: "white", 
                fontWeight: "bold",
                fontFamily: "Comic Neue !important", 
                fontSize: 16,
                p: 1,
              }}
            >
              venmo a gift?
            </Typography>
          </Button>
          <Button 
            sx={{ 
              backgroundColor: "black",
              borderRadius: 4, 
              '&:hover': {
                opacity: 1,
                backgroundColor: "black",
              },
            }}
            fullWidth
            onMouseDown={(e) => {
              // Use onMouseDown instead of onClick to capture the event earlier
              // This happens before blur events
              e.preventDefault();
              // Prevent any default behavior or event bubbling
              if (!chosenGuest) {
                toast.error("Please select your name!");
                return;
              }
              setFormMode2(1);
            }}
          >
            <Typography
              sx={{ 
                textAlign: "center", 
                color: "white", 
                fontWeight: "bold",
                fontFamily: "Comic Neue !important", 
                fontSize: 16,
                p: 1,
                pointerEvents: 'none', 
              }}
            >
              I've done the deed
            </Typography>
          </Button>
        </Box>
      </>
    )
  }

  const MoreCreditsModalContent1 = () => {
    console.log("totalRoll", totalRoll)
    return (
      <Box
        sx={{ 
          display: "flex",           // Add this to make justifyContent work
          flexDirection: "column",   // Stack items vertically
          alignItems: "center",      // Center items horizontally
          justifyContent: "center"
        }}
      >
        <Typography
          sx={{ 
            textAlign: "center", 
            fontWeight: "bold",
            fontFamily: "Comic Neue !important", 
            fontSize: 32,
          }}
        >
          {(totalRoll === 0) ? "determining amount from strength of our relationship..." : "relationship strength is..."}
        </Typography>
        {(totalRoll > 0) ? (
          <Box>
            <Button 
              sx={{ 
                backgroundColor: "#FF66C4", 
                borderRadius: 6, 
                mt: 2,
                '&:hover': {
                  opacity: 1,
                  backgroundColor: "#FF66C4",              },
              }}
              disabled={true}
            >
              <Sparkle />
              <Typography
                sx={{ 
                  textAlign: "left", 
                  fontWeight: "bold",
                  fontFamily: "Comic Neue !important", 
                  fontSize: 24,
                  p: 2,
                  color: "white",
                }}
              >
                {`${totalRoll} credits!`}
              </Typography>
            </Button>
          </Box>
        ) : (
          <Button 
            sx={{ 
              backgroundColor: "black", 
              borderRadius: 6, 
              mt: 2,
              '&:hover': {
                opacity: 1,
                backgroundColor: 'black'
              },
            }}
            onClick={() => {
              if (reactDice.current) {
                reactDice.current.rollAll();
              }
            }}
          >
            <Sparkle />
            <Typography
              sx={{ 
                textAlign: "center", 
                color: "white", 
                fontWeight: "bold",
                fontFamily: "Comic Neue !important", 
                fontSize: 24,
                p: 2,
              }}
            >
              TEST OUR RELATIONSHIP
            </Typography>
          </Button>
        )}
        {totalRoll === 0 && (
          <Box sx={{ mt: 4 }}>
            <ReactDice
              numDice={30}
              ref={reactDice}
              dieSize={isSmall ? 50 : 60}
              disableIndividual={true}
              rollDone={
                (totalValue: number, values: number[]) => {
                  let isInit = true;
                  if (totalValue !== 120) {
                    isInit = false;
                  }
                  if (isInit) {
                    for (let i = 0; i < values.length; i++) {
                      if (values[i] !== 4 ) {
                        isInit = false;
                        break;
                      }
                    }
                  }
                  if (!isInit) {
                    setTotalRoll(totalValue);

                    if (chosenGuest) {
                      const oldCredits = chosenGuest.credits || 0;
                      const newCredits = oldCredits + totalValue;
                      setChosenGuest({...chosenGuest, credits: newCredits});
                      
                      // Update the backend
                      const body: CreditUpdate = { 
                        guest_id: chosenGuest.guest_id!, 
                        credits: newCredits,
                      };
                      updateGuestCredits(body)
                        .then(() => {
                          toast.success(`${totalValue} credits added!`);
                        })
                        .catch((err) => {
                          console.error(err);
                          toast.error("Failed to update credits");
                        });
                    }
                  }
                }
              }
            />
          </Box>
        )}
      </Box>
    );
  }

  const PurchaseModalContent0 = () => {
    const cat = showDeck === 1 ? "woocat" : showDeck === 2 ? "loocat" : "wooloocat";
    const price = showDeck === 1 ? 5 : showDeck === 2 ? 10 : 20;
    const hasEnough = chosenGuest ? chosenGuest.credits! >= price : true;
    return (
      <>
        <Typography
          sx={{ 
            textAlign: "left", 
            fontWeight: "bold",
            fontFamily: "Comic Neue !important", 
            fontSize: 32,
          }}
        >
          {`In the market for a ${cat}?`}
        </Typography>
        <Box sx={{ width: "100%", my: 2 }}>
          <Typography
            sx={{ 
              textAlign: "left", 
              fontWeight: "bold",
              fontFamily: "Comic Neue !important", 
              fontSize: 18,
            }}
          >
            Who are you?
          </Typography>
          <GuestSelector />
        </Box>
        <Typography
          sx={{ 
            textAlign: "center", 
            fontWeight: "bold",
            fontFamily: "Comic Neue !important", 
            fontSize: 48,
            mt: 4,
          }}
        >
          {`costs ${price} creds`}
        </Typography>
        <Typography
          sx={{ 
            textAlign: "center", 
            fontWeight: "bold",
            color: hasEnough ? "#FF66C4" : "red",
            fontFamily: "Comic Neue !important", 
            fontSize: 24,
            mb: 4,
          }}
        >
          {`(you've got ${chosenGuest?.credits} left)`}
        </Typography>
        <Button 
          sx={{ 
            backgroundColor: hasEnough ? "black" : "red", 
            borderRadius: 4, 
            '&:hover': {
              opacity: 1,
              backgroundColor: hasEnough ? "black" : "red", 
            },
          }}
          fullWidth
          onMouseDown={(e) => {
            if (hasEnough) {
              // Use onMouseDown instead of onClick to capture the event earlier
              // This happens before blur events
              e.preventDefault();
              // Prevent any default behavior or event bubbling
              if (!chosenGuest) {
                toast.error("Please select your name!");
                return;
              }
              setFormMode(1);
            } else {
              e.preventDefault();
              setShowModal(false);
              setShowModal2(true);
            }
          }}
        >
          <Typography
            sx={{ 
              textAlign: "center", 
              color: "white", 
              fontWeight: "bold",
              fontFamily: "Comic Neue !important", 
              fontSize: 16,
              p: 1,
              pointerEvents: 'none', 
            }}
          >
            {hasEnough ? "Take my credits!" : "I need moar credits..."}
          </Typography>
        </Button>
      </>
    )
  }

  const PurchaseModalContent1 = () => {
    const cat = showDeck === 1 ? "woocat" : showDeck === 2 ? "loocat" : "wooloocat";
    return (
      <>
        <Typography
          sx={{ 
            textAlign: "center", 
            fontWeight: "bold",
            fontFamily: "Comic Neue !important", 
            fontSize: 24,
          }}
        >
          {`a ${cat} ehh?`}
        </Typography>
        <img 
          src="/regi3/triplecat.gif" 
          alt="Triple cat stares"
          style={{ maxWidth: '100%', borderRadius: 16, marginTop: 8 }}  
        />
        <Button 
          sx={{ 
            backgroundColor: 'black', 
            borderRadius: 4, 
            '&:hover': {
              opacity: 1,
              backgroundColor: 'black'
            },
            mt: 2,
          }}
          fullWidth
          onClick={() => {
            if (!chosenGuest) {
              toast.error('Did you forget to select your name?');
              return;
            }
            setFormMode(2);
            onMintNft(chosenGuest!.guest_id!, chosenGuest!.guest_name, showDeck)
          }}
        >
          <Sparkle />
          <Typography
            sx={{ 
              textAlign: "center", 
              color: "white", 
              fontWeight: "bold",
              fontFamily: "Comic Neue !important", 
              fontSize: 16,
              p: 1,
            }}
          >
            gimme my cat!!!
          </Typography>
        </Button>
      </>
    );
  }

  const PurchaseModalContent2 = () => {
    if (loadingNft || !mintedNft) {
      return (
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img 
            src="/regi3/meow-loading.gif" 
            alt="Spinning cat"
            style={{ maxWidth: '100%' }}  
          />
          <Typography
            sx={{ 
              textAlign: "center", 
              fontWeight: "bold",
              fontFamily: "Comic Neue !important", 
              fontSize: 24,
              mt: 2,
            }}
          >
            adding sugar, spice, and everything nice...
          </Typography>
        </Box>
      )
    }
    const getStars = (deck: number, score: number): number => {
      if (deck === 1) {
        if (score >= 12) {
          return 1;
        } else if (score >= 9) {
          return 2;
        } else if (score >= 5) {
          return 3;
        } else { 
          return 4;
        }
      } else if (deck === 2) {
        if (score >= 16) {
          return 1;
        } else if (score >= 12) {
          return 2;
        } else if (score >= 8) {
          return 3;
        } else { 
          return 4;
        }
      } else {
        if (score >= 20) {
          return 1;
        } else if (score >= 15) {
          return 2;
        } else if (score >= 10) {
          return 3;
        } else { 
          return 4;
        }
      }
    }
    const holoText = mintedNft.holo ? " shiny " : "";
    const numStars = getStars(mintedNft.deck, mintedNft.score);
    let starText: string = "";
    for (let i = 0; i < numStars; i++) {
      starText += "💎";
    }
    return (
      <Box>
        <Typography
          sx={{ 
            textAlign: "center", 
            fontWeight: "bold",
            fontFamily: "Comic Neue !important", 
            fontSize: 24,
            color: "black",
          }}
        >
          {`you got a${holoText} ${starText} ${mintedNft.cat}`}
        </Typography>
        <Box 
          sx={{ 
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <GatchaCard nft={mintedNft} />
        </Box>
        <Button 
          sx={{ 
            backgroundColor: "black", 
            borderRadius: 4, 
            '&:hover': {
              opacity: 1,
              backgroundColor: "black",
            },
            mt: 2,
          }}
          fullWidth
          onClick={() => navigate('/regi3/gallery')}
        >
          <Sparkle />
          <Typography
            sx={{ 
              textAlign: "center", 
              color: "white", 
              fontWeight: "bold",
              fontFamily: "Comic Neue !important", 
              fontSize: 16,
              p: 1,
            }}
          >
            view in gallery
          </Typography>
        </Button>
      </Box>
    );
  }

  const PurchaseModal = () => {
    return (
      <Modal 
        open={showModal} 
        onClose={() => {
          setShowModal(false);
          setFormMode(0);
        }}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent', // Remove background opacity
          }
        }}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isSmall ? "100%" : 400,
          bgcolor: formMode === 2 ? loadingNft ? "#E7E0DC" : "white" : 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          borderRadius: 4,
          p: 4,
        }}>
          {formMode === 2 && <PurchaseModalContent2 />}
          {formMode === 1 && <PurchaseModalContent1 />}
          {formMode === 0 && <PurchaseModalContent0 />}
        </Box>
      </Modal>
    );
  }

  const MoreCreditsModal = () => {
    return (
      <Modal 
        open={showModal2} 
        onClose={() => {
          setShowModal2(false);
          setFormMode2(0);
          setTotalRoll(0);
        }}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent', // Remove background opacity
          }
        }}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: "white",
          width: isSmall ? "100%" : 520,
          border: '2px solid #000',
          boxShadow: 24,
          borderRadius: 4,
          p: 4,
        }}>
          {formMode2 === 1 && <MoreCreditsModalContent1 />}
          {formMode2 === 0 && <MoreCreditsModalContent0 />}
        </Box>
      </Modal>
    );
  }

  return (
    <Box>
      <TitleScreen />
      <CarouselScreen />
      <InfoScreen />
      <InstructionScreen />
      <FooterScreen />
      <PurchaseModal />
      <MoreCreditsModal />
    </Box>
  );
}


export default RegistryView;