import { Box, Typography, Select, MenuItem, FormControl } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type InputProps = {
  diamondType?: number;
  setDiamondType: (diamondType?: number) => void;
}

const DiamondDropDown = ({ diamondType, setDiamondType }: InputProps) => {
  
  const handleChange = (event: any) => {
    const value = event.target.value;
    if (value === "") {
      setDiamondType(undefined);
    } else {
      setDiamondType(parseInt(value, 10));
    }
  };

  const valueToText = (value: number) => {
    if (value === 1) { return "💎"; }
    else if (value === 2) { return "💎💎"; }
    else if (value === 3) { return "💎💎💎"; }
    else { return "💎💎💎💎"; }
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 400 }}>
      <FormControl fullWidth>
        <Select
          value={diamondType}
          onChange={handleChange}
          displayEmpty
          IconComponent={KeyboardArrowDownIcon}
          sx={{
            bgcolor: 'black',
            color: 'white',
            border: '4px solid #7B68EE', // Purple border
            borderRadius: 2,
            fontWeight: 'bold',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '& .MuiSvgIcon-root': {
              color: '#7B68EE',
              fontSize: 40,
            },
            '& .MuiSelect-select': {
              padding: '14px 20px',
            }
          }}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <Typography
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: 28,
                    fontFamily: "Comic Neue !important", 
                  }}
                >
                  DIAMOND TYPE
                </Typography>
              );
            }
            return (
              <Typography
                sx={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 28,
                  fontFamily: "Comic Neue !important", 
                }}
              >
                {valueToText(selected)}
              </Typography>
            );
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: 'black',
                border: '4px solid #7B68EE',
                borderRadius: 2,
                marginTop: '5px',
                '& .MuiMenuItem-root': {
                  color: 'white',
                  fontSize: 24,
                  fontWeight: 'bold',
                  fontFamily: "Comic Neue !important", 
                  padding: '12px 20px',
                  '&:hover': {
                    bgcolor: '#7B68EE33',
                  },
                  '&.Mui-selected': {
                    bgcolor: '#7B68EE33',
                    '&:hover': {
                      bgcolor: '#7B68EE66',
                    }
                  }
                },
              },
            },
          }}
        >
          <MenuItem value="">ALL</MenuItem>
          <MenuItem value="1">💎</MenuItem>
          <MenuItem value="2">💎💎</MenuItem>
          <MenuItem value="3">💎💎💎</MenuItem>
          <MenuItem value="4">💎💎💎💎</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default DiamondDropDown;