import React, { useState, useEffect } from 'react';
import GatchaCard from './GatchaCard';
import toast from 'react-hot-toast';
import type { Nft, FavoriteUpdate, Guest } from "../utils/types";
import { fetchGuestById, fetchNftGallery, updateGuestFavoriteNft } from '../utils/services';
import { Box, Button, Grid, Typography } from '@mui/material';
import CatDropDown from "./CatDropDown"
import DeckDropDown from "./DeckDropDown"
import DiamondDropDown from "./DiamondDropDown"
import Cookies from 'js-cookie';

const COOKIE_KEY: string = 'wuliucom-regi3-user-id';

const GalleryView = () => {
  const [nfts, setNfts] = useState<Nft[]>([]);
  const [guest, setGuest] = useState<Guest>();
  const guestId: string | undefined = Cookies.get(COOKIE_KEY);
  const [catFilter, setCatFilter] = useState<string>();
  const [deckFilter, setDeckFilter] = useState<number>();
  const [diamondFilter, setDiamondFilter] = useState<number>();

  useEffect(() => {
    if (guestId) {
      fetchGuestById(guestId)
      .then((guest) => setGuest(guest));
    }
  }, [guestId]);

  useEffect(() => {
    fetchNftGallery()
    .then((nfts) => setNfts(nfts.sort((a, b) => b.created_at - a.created_at)))
    .catch((err) => console.error(err));
  }, [])

  const ControlScreen = () => {
    return ( 
      <Grid container columns={12} sx={{  mb: 12 }} rowSpacing={4}>
        <Grid 
          item 
          xs={12} sm={6} md={4} lg={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CatDropDown catType={catFilter} setCatType={setCatFilter} />
        </Grid>
        <Grid 
          item 
          xs={12} sm={6} md={4} lg={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DeckDropDown deckType={deckFilter} setDeckType={setDeckFilter} />
        </Grid>
        <Grid 
          item 
          xs={12} sm={6} md={4} lg={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DiamondDropDown diamondType={diamondFilter} setDiamondType={setDiamondFilter} />
        </Grid>
      </Grid>
    );
  }

  const TitleScreen = () => {
    return (
      <Box
        style={{
          width: "100%",
          minHeight: 300,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 40,
        }}
      >
        <img 
          src="/regi3/gallery.png" 
          alt="GALLERY title"
          style={{ maxWidth: '100%' }}  
        />
        <Typography 
          sx={{ 
            textAlign: "center", 
            color: "white", 
            fontWeight: "bold",
            fontFamily: "Comic Neue !important", 
            fontSize: 24,
            pt: 0,
          }}
        >
          want your own card? Back to the <a href="/regi3" style={{ color: "white" }}>regi3</a>
        </Typography>
      </Box>
    )
  }

  const getStars = (deck: number, score: number): number => {
    if (deck === 1) {
      if (score >= 12) {
        return 1;
      } else if (score >= 9) {
        return 2;
      } else if (score >= 5) {
        return 3;
      } else { 
        return 4;
      }
    } else if (deck === 2) {
      if (score >= 16) {
        return 1;
      } else if (score >= 12) {
        return 2;
      } else if (score >= 8) {
        return 3;
      } else { 
        return 4;
      }
    } else {
      if (score >= 20) {
        return 1;
      } else if (score >= 15) {
        return 2;
      } else if (score >= 10) {
        return 3;
      } else { 
        return 4;
      }
    }
  }

  const CardGrid = () => {
    let filteredNfts = [...nfts];
    if (catFilter) {
      filteredNfts = filteredNfts.filter(x => x.cat === catFilter);
    }
    if (deckFilter) {
      filteredNfts = filteredNfts.filter(x => x.deck === deckFilter);
    }
    if (diamondFilter) {
      filteredNfts = filteredNfts.filter(x => getStars(x.deck, x.score) === diamondFilter);
    }
    return (
      <Grid container columns={12}>
        {filteredNfts.map(nft => {
          return (
            <Grid 
              item lg={4} xs={12} md={4} sm={6} 
              key={`nft-${nft.nft_id!}`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                my: 4,
              }}
            >
              <GatchaCard nft={nft} />
              <Typography
                sx={{ 
                  textAlign: "center", 
                  color: "white", 
                  fontFamily: "Comic Neue !important", 
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                pulled by {nft.owner_name.toLowerCase()}
              </Typography>
              {(guestId === nft.guest_id) && (guest?.favorite_nft !== nft.nft_id) && (
                <Button
                  sx={{
                    backgroundColor: "transparent", // Make button background transparent
                    border: "none",
                    padding: 1,
                    '&:hover': {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                    borderRadius: 2,
                  }}
                  onClick={() => {
                    if (guestId) {
                      const body: FavoriteUpdate = {guest_id: guestId, nft_id: nft.nft_id!};
                      // Update backend
                      updateGuestFavoriteNft(body)
                      .then((guest) => {
                        toast.success("Updated your favorite!");
                        setGuest(guest);
                      })
                      .catch(() => toast.error("Something went wrong..."))
                    }
                  }}
                >
                  <Typography
                    sx={{ 
                      textAlign: "center", 
                      color: "#f819c7", 
                      fontFamily: "Comic Neue !important", 
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    set as favorite?
                  </Typography>
                </Button>
              )}
              {(guest?.favorite_nft === nft.nft_id) && (
                <Typography
                  sx={{ 
                    textAlign: "center", 
                    color: "#69ff00", 
                    fontFamily: "Comic Neue !important", 
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  your favorite!
                </Typography>
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  }

  const FooterScreen = () => {
    return (
      <Box sx={{ p: 8 }}>
        <Typography sx={{ textAlign: "center", color: "white", fontFamily: "Comic Neue !important" }}>
          Designed by Connie L. Built by Mike W. Art inspired by the <a href="https://opensea.io/collection/cool-cats-nft" style={{ color: "white" }}>Cool Cats NFT collection</a>. 
        </Typography>
      </Box>
    )
  }

  return (
    <Box  
      sx={{
        backgroundImage: `url(/regi3/starrysky-bg.png)`,
        backgroundSize: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat',
        justifyContent: "center"
      }}
    >
      <TitleScreen />
      <ControlScreen />
      <CardGrid />
      <FooterScreen />
    </Box>
  );
}

export default GalleryView;