import { axiosInstance } from "./axios";
import type { Entry, EntryCreate, EntryUpdate, Guest, GuestUpdate, CreditUpdate, FavoriteUpdate, Nft, NftCreate } from "./types";

/**
 * Fetch leaderboard entries
 * @returns Array of entries
 */
export const fetchLeaderboard = async (): Promise<Entry[]> => {
  return axiosInstance
  .get('/api/leaderboard', { params: { skip: 0, limit: 100 }})
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchLeaderboard`", err);
  });
};

/**
 * Fetch an entry by name
 */
export const fetchRsvp = async (name: string): Promise<Entry | undefined> => {
  return axiosInstance.get(`/api/leaderboard/${name}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchRsvp`", err);
  });
};

/**
 * Record an RSVP
 * @param entry 
 * @returns 
 */
export const recordRSVP = async (body: EntryCreate): Promise<Entry> => {
  return axiosInstance.post('/api/rsvp', body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `recordRSVP`", err);
  });
};

/**
 * Update the score of an entry
 * @param entry 
 * @returns 
 */
export const updateScore = async (body: EntryUpdate): Promise<Entry> => {
  return axiosInstance.post('/api/update', body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `updateScore`", err);
  });
};

/**
 * Fetch all guests
 */
export const fetchGuestList = async (): Promise<Guest[]> => {
  return axiosInstance
  .get(`/api/guests`, { params: { skip: 0, limit: 100 }})
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchGuests`", err);
  });
}

/**
 * Fetch a guest by id
 */
export const fetchGuestById = async (guestId: string): Promise<Guest | undefined> => {
  return axiosInstance.get(`/api/guest2/${guestId}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchGuestById`", err);
  });
};

/**
 * Fetch a guest by name
 */
export const fetchGuest = async (name: string): Promise<Guest | undefined> => {
  return axiosInstance.get(`/api/guest/${name}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchGuest`", err);
  });
};

/**
 * Update the data of a guest
 * @param guest
 * @returns 
 */
export const updateGuest = async (body: GuestUpdate): Promise<Guest> => {
  return axiosInstance.post('/api/update2', body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `updateGuest`", err);
  });
};

/**
 * Update the credits a guest has
 */
export const updateGuestCredits = async (body: CreditUpdate): Promise<Guest> => {
  return axiosInstance.post('/api/credits/update', body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `updateGuestCredits`", err);
  });
}

export const updateGuestFavoriteNft = async (body: FavoriteUpdate): Promise<Guest> => {
  return axiosInstance.post('/api/favorite/update', body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `updateGuestFavoriteNft`", err);
  });
}

/**
 * Fetch NFT
 */
export const fetchNft = async (nft_id: string): Promise<Nft> => {
  return axiosInstance.get(`/api/regi3/nft/${nft_id}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchNft`", err);
  });
}

/**
 * Fetch Guest's NFTs
 */
export const fetchNftsByGuest = async (guest_id: string): Promise<Nft[]> => {
  return axiosInstance.get(`/api/regi3/guest/${guest_id}`)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchNftsByGuest`", err);
  });
}

/**
 * Fetch NFTs by rarity
 */
export const fetchNftsByRarity = async (rarity: number): Promise<Nft[]> => {
  return axiosInstance.get(`/api/regi3/rarity`, { params: { rarity: rarity } })
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchNftsByRarity`", err);
  });
}


/**
 * Fetch all NFTs
 */
export const fetchNftGallery = async (): Promise<Nft[]> => {
  return axiosInstance.get('/api/regi3/gallery')
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `fetchNftGallery`", err);
  });
}

/**
 * Mint an NFT
 */
export const mintNft = async (body: NftCreate): Promise<Nft> => {
  return axiosInstance.post('/api/regi3/generate', body)
  .then((res) => res.data)
  .catch((err) => {
    console.error("Error in `mintNft`", err);
  });
}