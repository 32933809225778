import React, { useState, useRef, useEffect } from "react";
import type { Nft } from "../utils/types";
import { Box, Typography } from "@mui/material";

type InputProps = {
  nft: Nft;
}

const GatchaCard = ({ nft }: InputProps) => {
  const catType = nft.deck === 1 ? "woocat" : nft.deck === 2 ? "loocat" : "wooloocat";
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isAnimated, setIsAnimated] = useState<boolean>(false);
  const [styleVars, setStyleVars] = useState({
    gradientPos: "50% 50%",
    sparklePos: "50% 50%",
    opacity: "0.75",
    transform: "rotateX(0deg) rotateY(0deg)",
  });

  // Define rainbow colors for holographic effect
  const getRainbowColors = (deck: number) => {
    switch(deck) {
      case 1: // woocat (silver)
        return {
          color1: "#c0c0c0",
          color2: "#a0a0a0",
          color3: "#d8d8d8",
          color4: "#b0b0b0",
          color5: "#e0e0e0"
        };
      case 2: // loocat (gold)
        return {
          color1: "#ffd700",
          color2: "#ffa500",
          color3: "#ffcc00",
          color4: "#ffb700",
          color5: "#ffe666"
        };
      default: // wooloocat (diamond/rainbow)
        return {
          color1: "#ec9bb6", // pink
          color2: "#ccac6f", // gold
          color3: "#69e4a5", // green
          color4: "#8ec5d6", // blue
          color5: "#b98cce"  // purple
        };
    }
  };

  const getStars = (deck: number, score: number): number => {
    if (deck === 1) {
      if (score >= 12) {
        return 1;
      } else if (score >= 9) {
        return 2;
      } else if (score >= 5) {
        return 3;
      } else { 
        return 4;
      }
    } else if (deck === 2) {
      if (score >= 16) {
        return 1;
      } else if (score >= 12) {
        return 2;
      } else if (score >= 8) {
        return 3;
      } else { 
        return 4;
      }
    } else {
      if (score >= 20) {
        return 1;
      } else if (score >= 15) {
        return 2;
      } else if (score >= 10) {
        return 3;
      } else { 
        return 4;
      }
    }
  }
  const stars = getStars(nft.deck, nft.score);
  const starIcon = "diamond";
  const rainbowColors = getRainbowColors(nft.deck);
  // Check if card should have holographic effects
  const isHoloCard = nft.deck === 3 || stars === 4;

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!cardRef.current) return;
    
    const $card = cardRef.current;
    const rect = $card.getBoundingClientRect();
    
    // Get mouse position relative to card
    const l = e.clientX - rect.left;
    const t = e.clientY - rect.top;
    const h = rect.height;
    const w = rect.width;
    
    // Handle edge cases - ensure values are within the card bounds
    const boundedL = Math.max(0, Math.min(w, l));
    const boundedT = Math.max(0, Math.min(h, t));
    
    // Calculate percentages - similar to the original JS
    const px = Math.abs(Math.floor(100 / w * boundedL) - 100);
    const py = Math.abs(Math.floor(100 / h * boundedT) - 100);
    const pa = (50 - px) + (50 - py);
    
    // Calculate gradient and sparkle positions
    const lp = (50 + (px - 50) / 1.5);
    const tp = (50 + (py - 50) / 1.5);
    const px_spark = (50 + (px - 50) / 7);
    const py_spark = (50 + (py - 50) / 7);
    const p_opc = 20 + (Math.abs(pa) * 1.5);
    
    // Adjust rotation sensitivity for smoother movement
    const ty = ((tp - 50) / 1.2) * -1; // More X-axis rotation
    const tx = ((lp - 50) / 1.2) * 0.8; // More Y-axis rotation
    
    if (isHoloCard) {
      // Use requestAnimationFrame for smoother updates
      requestAnimationFrame(() => {
        // Update style variables
        setStyleVars({
          gradientPos: `${lp}% ${tp}%`,
          sparklePos: `${px_spark}% ${py_spark}%`,
          opacity: `${p_opc / 100}`,
          transform: `rotateX(${ty}deg) rotateY(${tx}deg)`,
        });
      });
    } else {
       // Use requestAnimationFrame for smoother updates
    requestAnimationFrame(() => {
      // Update style variables
      setStyleVars({
        gradientPos: "50% 50%",
        sparklePos: "50% 50%",
        opacity: "0.75",
        transform: `rotateX(${ty}deg) rotateY(${tx}deg)`,
      });
    });
    }
    // Remove animated class
    setIsAnimated(false);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    
    // Reset styles immediately for snap-back effect
    const animationTimeout = setTimeout(() => {
      setStyleVars({
        gradientPos: "50% 50%",
        sparklePos: "50% 50%",
        opacity: "0.75",
        transform: "rotateX(0deg) rotateY(0deg)",
      });
    }, 800); // Wait for transition to complete before starting animation
  
    // Store the timeout ID for cleanup
    return () => clearTimeout(animationTimeout);
  };

  // Clean up timeout on unmount
  // Set up mouse leave detection for the entire document
  useEffect(() => {
    if (isHoloCard) {
      const handleGlobalMouseMove = (e: MouseEvent) => {
        if (!cardRef.current) return;
        
        const rect = cardRef.current.getBoundingClientRect();
        
        // Check if mouse is outside the card
        if (
          e.clientX < rect.left ||
          e.clientX > rect.right ||
          e.clientY < rect.top ||
          e.clientY > rect.bottom
        ) {
          if (isHovering) {
            handleMouseLeave();
          }
        }
      };
      
      // Add global mouse move listener to catch when mouse leaves card
      document.addEventListener('mousemove', handleGlobalMouseMove);
      
      // Clean up timeout and event listener on unmount
      return () => {
        document.removeEventListener('mousemove', handleGlobalMouseMove);
      };
    }
  }, [isHovering, isHoloCard]);

  return (
    <Box
      ref={cardRef}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        position: "relative",
        width: 320,
        height: 450,
        overflow: "hidden",
        borderRadius: "5% / 3.5%",
        margin: "20px",
        zIndex: 10,
        touchAction: "none",
        backgroundColor: "#FFDA55",
        transformStyle: "preserve-3d",
        transformOrigin: "center",
        willChange: "transform, filter",
        transition: isHovering 
          ? "box-shadow 0.2s ease" 
          : "box-shadow 0.2s ease, transform 0.5s ease-out",
        transform: isHovering ? styleVars.transform : "rotateX(0deg) rotateY(0deg) rotateZ(0deg)",
        boxShadow: isHovering && isHoloCard
          ? nft.deck === 3
            ? `0 0 30px -5px white,
               0 0 10px -2px white,
               0 55px 35px -20px rgba(0, 0, 0, 0.5)`
            : `-20px -20px 30px -25px ${rainbowColors.color1}, 
               20px 20px 30px -25px ${rainbowColors.color2}, 
               -7px -7px 10px -5px ${rainbowColors.color1}, 
               7px 7px 10px -5px ${rainbowColors.color2}, 
               0 0 13px 4px rgba(255,255,255,0.3),
               0 55px 35px -20px rgba(0, 0, 0, 0.5)`
          : `-5px -5px 5px -5px rgba(0, 0, 0, 0.1), 
             5px 5px 5px -5px rgba(0, 0, 0, 0.1), 
             0 55px 35px -20px rgba(0, 0, 0, 0.5)`,
        animation: (isAnimated && isHoloCard) ? "holoCard 12s ease 0s 1" : "none",
        "&:before": {
          content: '""',
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          backgroundRepeat: "no-repeat",
          opacity: isHoloCard ? (isHovering ? 0.88 : 0) : 0,
          mixBlendMode: "color-dodge",
          transition: isHovering ? "all .33s ease" : "all .5s ease-out",
          backgroundPosition: isHovering ? styleVars.gradientPos : "50% 50%",
          backgroundSize: isHovering ? "250% 250%" : "300% 300%",
          backgroundImage: isHoloCard
            ? (isHovering
                ? nft.deck === 3 
                  ? `linear-gradient(
                      115deg,
                      transparent 20%,
                      ${rainbowColors.color1} 36%,
                      ${rainbowColors.color2} 43%,
                      ${rainbowColors.color3} 50%,
                      ${rainbowColors.color4} 57%,
                      ${rainbowColors.color5} 64%,
                      transparent 80%
                    )`
                  : `linear-gradient(
                      110deg,
                      transparent 25%,
                      ${rainbowColors.color1} 48%,
                      ${rainbowColors.color2} 52%,
                      transparent 75%
                    )`
                : `linear-gradient(
                    115deg,
                    transparent 0%,
                    ${rainbowColors.color1} 25%,
                    transparent 47%,
                    transparent 53%,
                    ${rainbowColors.color2} 75%,
                    transparent 100%
                  )`)
            : 'none',
          filter: isHovering ? "brightness(.66) contrast(1.33)" : "brightness(.5) contrast(1)",
          zIndex: 1,
          animation: (isAnimated && isHoloCard) ? "holoGradient 12s ease 0s 1" : "none",
        },
        "&:after": {
          content: '""',
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          backgroundRepeat: "no-repeat",
          backgroundImage: isHoloCard
            ? `url("https://assets.codepen.io/13471/sparkles.gif"), 
              url(https://assets.codepen.io/13471/holo.png), 
              linear-gradient(125deg, #ff008450 15%, #fca40040 30%, #ffff0030 40%, #00ff8a20 60%, #00cfff40 70%, #cc4cfa50 85%)`
            : `none`,
          backgroundPosition: isHovering ? styleVars.sparklePos : "50% 50%",
          backgroundSize: "160%",
          backgroundBlendMode: "overlay",
          zIndex: 2,
          filter: "brightness(1) contrast(1)",
          transition: "all .33s ease",
          mixBlendMode: "color-dodge",
          opacity: isHoloCard ? styleVars.opacity : 0,
          animation: (isAnimated && isHoloCard) ? "holoSparkle 12s ease 0s 1" : "none",
        },
        "@keyframes holoCard": {
          "0%, 100%": {
            transform: "rotateZ(0deg) rotateX(0deg) rotateY(0deg)"
          },
          "5%, 8%": {
            transform: "rotateZ(0deg) rotateX(6deg) rotateY(-20deg)"
          },
          "13%, 16%": {
            transform: "rotateZ(0deg) rotateX(-9deg) rotateY(32deg)"
          },
          "35%, 38%": {
            transform: "rotateZ(3deg) rotateX(12deg) rotateY(20deg)"
          },
          "55%": {
            transform: "rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg)"
          }
        },
        "@keyframes holoGradient": {
          "0%, 100%": {
            opacity: 0.5,
            backgroundPosition: "50% 50%",
            filter: "brightness(.5) contrast(1)"
          },
          "5%, 9%": {
            backgroundPosition: "100% 100%",
            opacity: 1,
            filter: "brightness(.75) contrast(1.25)"
          },
          "13%, 17%": {
            backgroundPosition: "0% 0%",
            opacity: 0.88
          },
          "35%, 39%": {
            backgroundPosition: "100% 100%",
            opacity: 1,
            filter: "brightness(.5) contrast(1)"
          },
          "55%": {
            backgroundPosition: "0% 0%",
            opacity: 1,
            filter: "brightness(.75) contrast(1.25)"
          }
        },
        "@keyframes holoSparkle": {
          "0%, 100%": {
            opacity: 0.75,
            backgroundPosition: "50% 50%",
            filter: "brightness(1.2) contrast(1.25)"
          },
          "5%, 8%": {
            opacity: 1,
            backgroundPosition: "40% 40%",
            filter: "brightness(.8) contrast(1.2)"
          },
          "13%, 16%": {
            opacity: 0.5,
            backgroundPosition: "50% 50%",
            filter: "brightness(1.2) contrast(.8)"
          },
          "35%, 38%": {
            opacity: 1,
            backgroundPosition: "60% 60%",
            filter: "brightness(1) contrast(1)"
          },
          "55%": {
            opacity: 0.33,
            backgroundPosition: "45% 45%",
            filter: "brightness(1.2) contrast(1.25)"
          }
        }
      }}
    >
      <Box
        sx={{ 
          backgroundColor: "#D9D9D9",
          borderRadius: 4,
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          border: "5px solid black", 
        }}
        style={{
          width: 300,
          height: 430,
          left: 10,
          top: 10,
        }}
      >
        {Array.from({length: stars}, (_, i) => i).map(x => {
          return (
            <img 
              src={`${process.env.PUBLIC_URL}/regi3/${starIcon}.png`} 
              alt="star icon"
              key={`star-${x}`}
              style={{
                position: "absolute",
                right: x*20+10,
                top: 5,
                width: 16,
                height: 16,
              }}
            />
          );
        })}
        <Typography
          sx={{ 
            textAlign: "center", 
            fontWeight: "bold",
            fontFamily: "Comic Neue !important", 
            fontSize: 30,
            position: "absolute",
            color:"black",
          }}
          style={{
            left: 14
          }}
        >
          {nft.cat}
        </Typography>
        <Typography
          sx={{ 
            textAlign: "center", 
            fontWeight: "bold",
            fontFamily: "Comic Neue !important", 
            fontSize: 16,
            position: "absolute",
            color:"black",
          }}
          style={{
            right: 14,
            top: 16,
          }}
        >
          {catType}
        </Typography>
        <Box
          sx={{ 
            backgroundImage: `url(/nfts/backgrounds/${nft.background}.png)`,
            backgroundSize: 'cover', // Options: 'cover', 'contain', '100% 100%'
            backgroundPosition: 'center', // Optional: centers the image
            backgroundRepeat: 'no-repeat', // Prevents image from repeating
            borderRadius: 0,
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            border: "5px solid black", 
          }}
          style={{
            width: 270,
            height: 270,
            top: 40,
            left: 10,
          }}
        >
          <Box
            sx={{ 
              backgroundImage: `url(/nfts/cats/${nft.cat}.png)`,
              backgroundSize: 'cover', // Options: 'cover', 'contain', '100% 100%'
              backgroundPosition: 'center', // Optional: centers the image
              backgroundRepeat: 'no-repeat', // Prevents image from repeating
              position: "absolute",
            }}
            style={{
              width: 270,
              height: 270,
              bottom: -5,
              left: 0,
            }}
          />
          {nft.hat && (
            <Box
              sx={{ 
                backgroundImage: `url(/nfts/hats/${nft.hat}.png)`,
                backgroundSize: 'cover', // Options: 'cover', 'contain', '100% 100%'
                backgroundPosition: 'center', // Optional: centers the image
                backgroundRepeat: 'no-repeat', // Prevents image from repeating
                position: "absolute",
              }}
              style={{
                width: 270,
                height: 270,
                top: 0,
                left: 0,
              }}
            />
          )}
          {nft.shirt && (
            <Box
              sx={{ 
                backgroundImage: `url(/nfts/shirts/${nft.shirt}.png)`,
                backgroundSize: 'cover', // Options: 'cover', 'contain', '100% 100%'
                backgroundPosition: 'center', // Optional: centers the image
                backgroundRepeat: 'no-repeat', // Prevents image from repeating
                position: "absolute",
              }}
              style={{
                width: 130,
                height: 90,
                bottom: -5,
                left: 70,
              }}
            />
          )}
          {nft.accessory && (
            <Box
              sx={{ 
                backgroundImage: `url(/nfts/accessories/${nft.accessory}.png)`,
                backgroundSize: 'cover', // Options: 'cover', 'contain', '100% 100%'
                backgroundPosition: 'center', // Optional: centers the image
                backgroundRepeat: 'no-repeat', // Prevents image from repeating
                position: "absolute",
              }}
              style={{
                width: 270,
                height: 270,
                top: 0,
                left: 0,
              }}
            />
          )}
        </Box>
        <Box
          sx={{ 
            backgroundColor: "white",
            borderRadius: 0,
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            border: "5px solid black",
            p: 1.5, 
          }}
          style={{
            width: 270,
            height: 80,
            bottom: 20,
            left: 10,
          }}
        >
          <Typography
            sx={{ 
              textAlign: "left", 
              fontFamily: "Comic Neue !important", 
              fontSize: 12,
              lineHeight: 1,
            }}
          >
            {nft.description || "N/A"}
          </Typography>
        </Box>
        <Typography
          sx={{ 
            textAlign: "center", 
            fontWeight: "bold",
            fontFamily: "Comic Neue !important", 
            fontSize: 12,
            position: "absolute",
          }}
          style={{
            bottom: 2,
            right: 10,
          }}
        >
          {`ID: ${nft.nft_id!}`}
        </Typography>
      </Box>
    </Box>
  );
}

export default GatchaCard;