import React from 'react';
import { Box, Grid,  Typography } from '@mui/material';
import MenuCard from './MenuCard';
import { Link } from 'react-router-dom';
import type { CardType } from './utils';

const MenuView = () => {
  const cards: CardType[] = [
    {
      title: "play",
      image: "/menu/play.png",
      bgColor: "#caaacd",
      borderColor: "#feefff",
      link: "/invite",
    },
    {
      title: "rsvp",
      image: "/menu/rsvp.png",
      bgColor: "#ef601e",
      borderColor: "#fdf3dd",
      link: "/rsvp",
    },
    {
      title: "gift",
      image: "/menu/gift.png",
      bgColor: "#ef601e",
      borderColor: "#fdf3dd",
      link: "/regi3",
    },
    {
      title: "dance",
      subtitle: "coming soon",
      image: "/menu/dance.png",
      bgColor: "#caaacd",
      borderColor: "#feefff",
      link: undefined,
    }
  ]

  const isSmall = window.innerWidth < 768;
  return (
    <Grid
      container 
      columns={12}
      sx={{ 
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: "row",
        bgcolor: "#d6e8f7",
      }}
    >
      <Grid 
        item xs={12} sm={12} md={6} lg={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          p: 2,
        }}
      >
        <Typography
          sx={{ 
            textAlign: "center", 
            color: "#373d3b", 
            fontFamily: "Montserrat !important",
            fontSize: 30, 
          }}
        >
          THE WEDDING OF
        </Typography>
        <Typography
          sx={{ 
            textAlign: "center", 
            color: "#373d3b", 
            fontWeight: "bold",
            fontFamily: "Lexend !important", 
            fontSize: isSmall ? 80 : 120,
          }}
        >
          WU+LIU
        </Typography>
        <Typography
          sx={{ 
            textAlign: "center", 
            color: "#373d3b", 
            fontFamily: "Montserrat !important",
            fontSize: 24, 
          }}
        >
          Friday, June 13 in Los Angeles
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Grid
            container 
            columns={12}
            sx={{ 
              minHeight: '80vh',
              width: '100%',
              display: 'flex',
              flexDirection: "row",
              bgcolor: "#d6e8f7",
            }}
            spacing={4}
          >
            {cards.map((card, index) => (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {card.link ? (
                  <Link to={card.link} style={{ textDecoration: 'none' }}>
                    <MenuCard key={`card-${index}`} card={card} />
                  </Link>
                ) : (
                  <MenuCard key={`card-${index}`} card={card} />
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default MenuView;